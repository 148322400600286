
import { Vue, Options } from 'vue-class-component';
import { BaseButton, BaseIcon, BaseSelectDropdown, LegalPolicy } from '@/lib/components';
import FloatingBrandShapesLayout from '@/lib/layouts/FloatingBrandShapesLayout.vue';
import { useSessionStore } from '@/stores/session.store';

@Options({
  components: {
    BaseButton,
    BaseIcon,
    FloatingBrandShapesLayout,
    LegalPolicy,
    BaseSelectDropdown
  }
})
export default class AgreementPage extends Vue {
  loading = true;
  agreed = false;
  saving = false;
  selectedRegionId: string | null = null;
  sessionStore = useSessionStore();

  get allLegalRegions(): string[] {
    return this.sessionStore.legalRegions;
  }

  get legalRegionsDropdownOptions() {
    return this.allLegalRegions.map((region) => ({
      value: region,
      label: this.$t(`platform.countries.${region.toUpperCase()}`)
    }));
  }

  get branding() {
    return this.sessionStore.customerBranding;
  }

  get acceptTermsLabel() {
    return this.$te(`platform.user-agreement.${this.branding}-accept-terms`)
      ? this.$t(`platform.user-agreement.${this.branding}-accept-terms`)
      : this.$t('platform.user-agreement.accept-terms');
  }

  created() {
    this.selectedRegionId = this.allLegalRegions[0];
  }

  async submit() {
    this.saving = true;

    await this.sessionStore.saveUser({
      agreed_to_current_terms: this.agreed
    });

    if (this.sessionStore.currentUser?.is_admin) {
      await this.$router.push({ name: 'domain-admin' });

      return;
    }

    await this.$router.push({ name: 'patient-list' });
  }
}
